const styles = {
  container:
    "flex flex-col gap-x-6 gap-y-6 cursor-pointer group hover:scale-98 duration-200",

  head: "",
  body: "flex flex-col gap-y-1",

  image:
    "relative aspect-video bg-gray-200 rounded-md overflow-hidden !object-cover",

  title: "text-black group-hover:text-primary-600",
  description: "text-xs text-gray-600 leading-6",
  date: "text-xs uppercase text-gray-500",
};

export default styles;
