const styles = {
  container: "",

  head: {
    container: "col-span-9 lg:col-span-6",
    content: "pt-4",

    primary: {
      container: "mb-6",
    },

    secondary: {
      container: "grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-6 mb-6",
    },

    tertiary: {
      container: "flex flex-col gap-y-6",
    },
  },
};

export default styles;
