'use client';

import React, { useState } from 'react';
import { APIResponseData } from 'types/models';
import { TNextClientComponent } from 'types/next';
import AdItemCustom from 'components/custom/AdItem/AdItemCustom';
import ArticleItemCustom from 'components/custom/ArticleItem/ArticleItemCustom';
import { Input } from 'components/ui/input';
import { styles } from '.';
type TProps = {
  articles: APIResponseData<'api::article.article'>[];
  ads: APIResponseData<'api::advertisement.advertisement'>[];
  lang: string;
};
const ProxyComp = ({
  article,
  index,
  ads
}: {
  article: APIResponseData<'api::article.article'>;
  index: number;
  ads: APIResponseData<'api::advertisement.advertisement'>[];
}) => {
  const selector = index % ads?.length;
  if (index !== 1 && index !== 0 && (index + 1) % 6 === 1) {
    return <div key={`ad-${ads?.[selector]?.id}-${index}-container bg-red-300`}>
        <div className="mb-7">
          <AdItemCustom ad={ads?.[selector] as any} />
        </div>
        <ArticleItemCustom type="tertiary" article={article} lang="en" />
      </div>;
  }
  return <ArticleItemCustom key={`article-${article?.id}-${index}-tertiary-1`} type="tertiary" article={article} lang="en" data-sentry-element="ArticleItemCustom" data-sentry-component="ProxyComp" data-sentry-source-file="Client.tsx" />;
};
const Client: TNextClientComponent<TProps> = props => {
  const {
    articles,
    ads
  } = props;
  const [query, setQuery] = useState('');
  return <div className={styles.head.container} data-sentry-component="Client" data-sentry-source-file="Client.tsx">
      <Input placeholder="Search..." value={query} onChange={e => {
      setQuery(e.currentTarget.value);
    }} data-sentry-element="Input" data-sentry-source-file="Client.tsx" />
      <div className={styles.head.content}>
        <div className={styles.head.primary.container}>
          {query === '' && articles?.filter(article => article?.attributes?.type === 'PRIMARY')?.sort((a, b) => {
          if ((a?.attributes?.sequence_number ?? 0) < (b?.attributes?.sequence_number ?? 0)) {
            return -1;
          }
          return 1;
        })?.map((article, index) => <ArticleItemCustom key={`article-${article.id}-${index}-primary-0`} type="primary" article={article} lang="en" />)}
        </div>
        <div className={styles.head.secondary.container}>
          {query === '' && articles?.filter(article => article?.attributes?.type === 'SECONDARY')?.sort((a, b) => {
          if ((a?.attributes?.sequence_number ?? 0) < (b?.attributes?.sequence_number ?? 0)) {
            return -1;
          }
          return 1;
        })?.map((article, index) => <ArticleItemCustom key={`article-${article.id}-${index}-secondary-0`} type="secondary" article={article} lang="en" />)}
        </div>
        <div className={styles.head.tertiary.container}>
          {articles?.filter(article => article?.attributes?.type === 'TERTIARY')?.filter(article => query !== '' ? article?.attributes?.title?.toLowerCase().includes(query.toLocaleLowerCase()) : true)?.sort((a, b) => {
          if ((a?.attributes?.sequence_number ?? 0) < (b?.attributes?.sequence_number ?? 0)) {
            return -1;
          }
          return 1;
        })?.map((article, index) => <ProxyComp key={article.id} article={article} index={index} ads={ads} />)}
        </div>
      </div>
    </div>;
};
export default Client;