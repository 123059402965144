import React, { FC } from "react";
import { handleFixImageUrl } from "helpers";
import { toClassName } from "helpers/format";
import { APIResponseData } from "types/models";
import { styles } from ".";
type TProps = {
  ad: APIResponseData<"api::advertisement.advertisement">;
};
const AdItemCustom: FC<TProps> = props => {
  const {
    ad
  } = props;
  return <div className={toClassName(styles.container)} style={{
    backgroundImage: `url(${handleFixImageUrl(ad?.attributes?.image?.data?.attributes?.url ?? "")})`
  }} data-sentry-component="AdItemCustom" data-sentry-source-file="AdItemCustom.tsx" />;
};
export default AdItemCustom;